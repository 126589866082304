import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import maleIcon from "../../assets/icons/male.svg"
import femaleIcon from "../../assets/icons/female.svg"


const CustomBarChart = ({data = []}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={800}
                data={data}
                margin={{
                    top: 40,
                    right: 30,
                    left: 20,
                    bottom: 50,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="code"/>
                <YAxis/>
                <Tooltip/>
                <Legend iconType={"circle"} content={<div className={'flex justify-center'}>
                    <img width={40} className={'mr-5'} src={maleIcon} alt=""/>
                    <img width={40} src={femaleIcon} alt=""/>
                </div>}/>
                <Bar dataKey="male" stackId="a" fill="#8CC0DE"/>
                <Bar dataKey="female" stackId="a" fill="#FFD9C0"/>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomBarChart;