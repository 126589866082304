import React from 'react';
import Title from "../../../components/title";

const ErrorPage = () => {
    return (
        <div>
            <Title>504 timeout</Title>
        </div>
    );
};

export default ErrorPage;