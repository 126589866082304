export const URLS = {
    oauth:'/oauth/authorize',
    token:'/oauth/token',
    getMe: '/auth/me',
    login: '/auth/login',
    outpatientReports:'/reports/outpatient',
    inpatientReports:'/reports/inpatient',
    baseRates:'/base-rates',
    inpatientBaseRates:'/inpatient-base-rates',
    patientGroups:'/patient-groups',
    drgs:'/drgs',
    organizationLevel:'/organizations/levels',
    organizationType:'/organizations/types',
    locales:'/site/locales',
    translations:'/site/translations',
    translationsKey:'/site/translations/key',
    reports:'/reports',
    drg_report:'/reports/drg_report',
    form_66_report:'/reports/statistical_form_066_report',
    states:'/states',
    cities:'/cities',
    dashboardReports:'/dashboard/reports/outpatient',
}