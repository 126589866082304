import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {get} from "lodash";
import clsx from "clsx";


const CustomDatepicker = ({
                              property,
                              dateFormat = "yyyy/MM/dd",
                              excludeDates = [],
                              includeDates = [],
                              handleDate = () => {
                              }
                          }) => {
    const [startDate, setStartDate] = useState(new Date());
    useEffect(() => {
        if (startDate) {
            handleDate(startDate);
        }
    }, [startDate])
    return (
        <>
            <DatePicker
                includeDates={includeDates.map(item => new Date(item))}
                dateFormat={dateFormat}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showMonthYearPicker={get(property, 'showMonthYearPicker')}
                className={clsx('form-input')}
            />
        </>
    );
};

export default CustomDatepicker;