import React from 'react';
import Title from "../../../components/title";

const NotFoundPage = () => {
    return (
        <div>
           <Title> 404 page not found</Title>
        </div>
    );
};

export default NotFoundPage;