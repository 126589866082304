import React from 'react';

const ForbiddenPage = () => {
    return (
        <div>
            403
        </div>
    );
};

export default ForbiddenPage;