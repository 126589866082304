import React, {useEffect, useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {Tab, Tabs} from "../../../components/tab";
import Section from "../../../components/section";
import {Link, useSearchParams} from 'react-router-dom';
import Checkbox from "../../../components/checkbox";
import {get, includes, isArray, isEmpty, filter as filterArray, uniq, isEqual,find} from "lodash";
import {BarChart} from "../../../components/chart";
import {useGetAllQuery} from "../../../hooks/api";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import dayjs from "dayjs";
import {OverlayLoader} from "../../../components/loader";
import {calculateDashboardChartData} from "../../../utils";
import Select from "../../../components/select";
import CustomDatepicker from "../../../components/datepicker";


const DashboardContainer = () => {
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [regions, setRegions] = useState([])
    const [city, setCity] = useState([])
    const [month, setMonth] = useState(dayjs().format('YYYYMM'))
    const [regionId, setRegionId] = useState(null);
    const {data: regionList, isLoading: isLoadingRegions} = useGetAllQuery({
        key: KEYS.states, url: URLS.states,
    })
    const {data: cityList, isLoading: isLoadingCity} = useGetAllQuery({
        key: KEYS.cities,
        url: get(regionId, 'value') ? `${URLS.states}/${get(regionId, 'value')}${URLS.cities}` : URLS.cities,
        params: {
            params: {
                per_page: 1000
            }
        },
        enabled: searchParams.get('tab') === 'P2'
    })
    const {data: reports, isLoading: isLoadingReports} = useGetAllQuery({
        key: KEYS.dashboardReports,
        url: `${URLS.dashboardReports}/${month}/states`,
        params: {params: {state_ids: regions}},
        enabled: searchParams.get('tab') === 'P1'
    })
    const {data: dateList} = useGetAllQuery({
        key: KEYS.outpatientReports,
        url: URLS.outpatientReports,
        params: {
            params: {
                per_page: 1000
            }
        }
    })
    const {data: cityReports, isLoading: isLoadingCityReports} = useGetAllQuery({
        key: KEYS.dashboardReports,
        url: `${URLS.dashboardReports}/${month}/states/${get(regionId, 'value')}/organizations`,
        params: {params: {city_ids: city}},
        enabled: searchParams.get('tab') === 'P2'
    })

    useEffect(() => {
        if (get(regionList, 'data.data') && isArray(get(regionList, 'data.data'))) {
            setRegions(get(regionList, 'data.data', []).map(({id}) => id))
        }
    }, [regionList]);

    useEffect(() => {
        if (get(cityList, 'data.data') && isArray(get(cityList, 'data.data'))) {
            setCity(get(cityList, 'data.data', []).map(({id}) => id))
        }
    }, [cityList]);

    useEffect(() => {
        if(!isEmpty(get(regionList, 'data.data', []))){
            setRegionId({value:get(find(get(regionList, 'data.data', []),(_item)=>get(_item,'id') == searchParams.get('regionId')),'id'),label:get(find(get(regionList, 'data.data', []),(_item)=>get(_item,'id') == searchParams.get('regionId')),'name')})
        }
    }, [searchParams.get('regionId'),get(regionList, 'data.data', [])]);

    return (
        <div>
            <Tabs>
                <Tab tab={'info'} label={t("Title page")}>
                    <Section>
                        <Title className={'mb-4 !text-2xl'}>{t("Анализ информации медицинских организаций")}</Title>
                        <div>
                            <div>
                                <div
                                    className={'bg-[#E5F0F3] py-1 px-2 font-semibold text-lg'}>{t("Раздел 1. Прикрепленное население")}
                                </div>
                                <ul className={'py-5'}>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура прикрепленного населения в разрезе возрастных групп и пола')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=P1',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>P1
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура прикрепленного населения в разрезе организаций ПМСП')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=P2',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>P2
                                        </Link>
                                    </li>
                                    <li className={' flex justify-between'}>
                                        <span>{t('Структура прикрепленного населений в разрезе возрастных групп, %')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=P3',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>P3
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div className={'bg-[#E5F0F3] py-1 px-2 font-semibold text-lg'}>
                                    {t('Раздел 2. Анализ стационарных госпитализаций (Терапия)')}
                                </div>
                                <ul className={'py-5'}>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура госпитализаций в разрезе стационаров')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H1',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H1
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура больничной смертности в разрезе классов МКБ10')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H2',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H2
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура госпитализаций по классам МКБ10 в разрезе стационаров')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H3',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H3
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Анализ среднего пребывания по выбранной клинической категории')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H4',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H4
                                        </Link>
                                    </li>
                                    <li className={' flex justify-between'}>
                                        <span>{t('Анализ спектра госпитализаций в разрезе классов МКБ10')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H5',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H5
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div className={'bg-[#E5F0F3] py-1 px-2 font-semibold text-lg'}>
                                    {t('Раздел 3. Анализ стационарных госпитализаций (Хирургия)')}
                                </div>
                                <ul className={'py-5'}>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура хирургических операций в разрезе стационаров')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=HS1',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>HS1
                                        </Link>
                                    </li>
                                    <li className={'flex justify-between'}>
                                        <span>{t('Структура хирургических операций в разрезе типов операций по стационарам')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=HS2',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>HS2
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Section>
                </Tab>
                <Tab tab={'P1'} label={<div>{t("Прикрепленное население")}
                    <div>P1</div>
                </div>}>
                    <Section>
                        <Title
                            className={'mb-4 !text-2xl'}>{t("Структура прикрепленного населения в разрезе возрастных групп и пола")}</Title>
                        <div className="grid grid-cols-12">
                            <div className={'col-span-2'}>
                                {isLoadingRegions && <OverlayLoader/>}
                                {<>
                                    <h3 className={'font-bold text-sm'}>{t("Region")}</h3>
                                    <ul className={'py-2.5'}>
                                        <li className={'mb-2 flex items-center'}><Checkbox
                                            checked={!isEmpty(regions)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setRegions(get(regionList, 'data.data', []).map(({id}) => id))
                                                } else {
                                                    setRegions([])
                                                }
                                            }}
                                        />
                                            <span>{t('All')}</span>
                                        </li>
                                        {
                                            isArray(get(regionList, 'data.data', [])) && get(regionList, 'data.data', []).map((region) =>
                                                <li key={get(region, 'id')} className={'mb-2 flex items-center'}>
                                                    <Checkbox
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setRegions(uniq([...regions, get(region, 'id')]))
                                                            } else {
                                                                setRegions(uniq(filterArray(regions, (_region) => !isEqual(_region, get(region, 'id')))))
                                                            }
                                                        }}
                                                        checked={includes(regions, get(region, 'id'))}
                                                    />
                                                    <Link to={{
                                                        pathname: '/',
                                                        search: `?tab=P2&regionId=${get(region,'id')}`,
                                                    }}>{get(region, 'name')}</Link>
                                                </li>)
                                        }
                                    </ul>
                                </>}
                            </div>
                            <div className="col-span-10 h-[675px]">
                                {(isLoadingReports) && <OverlayLoader/>}
                                <div className="flex justify-end w-48 ml-auto mb-0 relative">
                                    <CustomDatepicker
                                        handleDate={(val) => setMonth(dayjs(val).format('YYYYMM'))}
                                        includeDates={get(dateList, 'data.data', []).map(({month}) => month) || []}
                                        property={{showMonthYearPicker: true}} dateFormat="MM/yyyy"/>
                                </div>
                                <BarChart
                                    data={calculateDashboardChartData(get(reports, 'data.data', []), regions)}/>
                            </div>
                        </div>
                    </Section>
                </Tab>
                <Tab tab={'P2'} label={<div>{t("Прикрепленное население")}
                    <div>P2</div>
                </div>}>
                    <Section>
                        <Title
                            className={'mb-4 !text-2xl'}>{t("Структура прикрепленного населения в разрезе возрастных групп и пола")}</Title>
                        <div className="grid grid-cols-12">
                            <div className={'col-span-2'}>
                                {isLoadingCity && <OverlayLoader/>}
                                <Select
                                    value={regionId}
                                    setValue={(val) => setRegionId(val)}
                                    label={t('Region')} options={get(regionList, 'data.data', []).map(item => ({
                                    value: get(item, 'id'),
                                    label: get(item, 'name')
                                }))}/>
                                <h3 className={'mt-3 font-bold text-sm'}>{t("Cities")}</h3>
                                <ul className={'py-2.5 max-h-[60vh] overflow-y-auto'}>
                                    <li className={'mb-2 flex items-center'}><Checkbox
                                        checked={!isEmpty(city)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setCity(get(cityList, 'data.data', []).map(({id}) => id))
                                            } else {
                                                setCity([])
                                            }
                                        }}
                                    />
                                        <span>{t('All')}</span>
                                    </li>
                                    {
                                        isArray(get(cityList, 'data.data', [])) && get(cityList, 'data.data', []).map((_city) =>
                                            <li key={get(_city, 'id')} className={'mb-2 flex items-center'}><Checkbox
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setCity(uniq([...city, get(_city, 'id')]))
                                                    } else {
                                                        setCity(uniq(filterArray(city, (__city) => !isEqual(__city, get(_city, 'id')))))
                                                    }
                                                }}
                                                checked={includes(city, get(_city, 'id'))}
                                            />
                                                <span>{get(_city, 'name')}</span>
                                            </li>)
                                    }
                                </ul>
                            </div>
                            <div className="col-span-10 h-[675px]">
                                {isLoadingCityReports && <OverlayLoader/>}
                                <div className="flex justify-end w-48 ml-auto mb-0 relative">
                                    <CustomDatepicker
                                        handleDate={(val) => setMonth(dayjs(val).format('YYYYMM'))}
                                        includeDates={get(dateList, 'data.data', []).map(({month}) => month) || []}
                                        property={{showMonthYearPicker: true}} dateFormat="MM/yyyy"/>
                                </div>
                                <BarChart
                                    data={calculateDashboardChartData(get(cityReports, 'data.data', []), city, ['total_male', 'total_female'])}/>
                            </div>
                        </div>
                    </Section>
                </Tab>
            </Tabs>
        </div>
    );
};

export default DashboardContainer;